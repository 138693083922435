import { render, staticRenderFns } from "./FormCampaign.vue?vue&type=template&id=583d273f"
import script from "./FormCampaign.vue?vue&type=script&lang=js"
export * from "./FormCampaign.vue?vue&type=script&lang=js"
import style0 from "./FormCampaign.vue?vue&type=style&index=0&id=583d273f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QField from 'quasar/src/components/field/QField.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QDialog,QCard,QForm,QToolbar,QToolbarTitle,QCardSection,QTable,QTd,QChip,QCardActions,QBtn,QBreadcrumbs,QBreadcrumbsEl,QSeparator,QStepper,QStep,QCheckbox,QInput,QSelect,QUploader,QFile,QImg,QTooltip,QRadio,QField});qInstall(component, 'directives', {ClosePopup});
